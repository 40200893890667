export const AppMessage = {
    GET_PROCESS_TIMEOUT: "Tiempo de espera excedido, revise sus movimientos en unos minutos o intente de nuevo mas tarde",
    WAITING_TWOFA: "En espera de método de autenticación",
    GET_PROCESS_GENERAL_ERROR: "Ocurrió un error al realizar el proceso",
    TWOFA_TIMEOUT: "Se excedió el tiempo para ingresar su segundo factor de autenticación",
    INVALID_CREDENTIALS: "Usuario no autorizado",
    USER_INTERACTION_REQUIRED: "Ocurrió un error al acceder a su cuenta, inicie sesión en su portal bancario y vuelva a intentar la descarga",
    PROVIDER_ERROR: "Error al consultar al proveedor",
    FETCH_PRODUCT_ERROR: "Ocurrió un error al cargar los bancos disponibles",
    LICENCE_REQUIRED: "El código de licencia es obligatorio",
    TWOFA_REQUIRED: "El token de autenticación es obligatorio",
    PRODUCT_FIELD_REQUIRED: "El campo {field} es obligatorio",
    WAIT_GET_PROCESS: "Este proceso puede tardar unos minutos, por favor no recargue la página",
    GENERAL_ERROR: "Error al realizar la operación, intente de nuevo",
    CREDENTIAL_ERROR: "Ocurrió un error al cargar los datos bancarios, intente nuevamente",
    ENCRYPT_ERROR: "Ocurrió un error, por favor intente de nuevo mas tarde",
    FETCH_BANK_ACCOUNT_FAILED: "Ocurrió un error al consultar las cuentas bancarias, intente de nuevo",
    FETCH_BANK_MOVEMENT_FAILED: "Ocurrió un error al cargar los movimientos de la cuenta seleccionada",
    GET_PROCESS_CONTINUE: "Continuamos procesando la información, por favor no recargue la página",
    SUCCESS_SYNC_VALIDATE_LATER: "Su descarga de movimientos ha sido exitosa, las cuentas y movimientos pueden tardar unos minutos en verse reflejados",
    SUCCESS_SEND_TWOFA_CODE: "El código de autenticación ha sido enviado, verifique su cuenta seleccionada",
    ACCOUNTS_NOT_FOUND: "No se encuentran cuentas para la licencia y banco seleccionado",
    MOVEMENTS_NOT_FOUND: "No se encontraron movimientos con la cuenta y filtros seleccionados",
    PAYMENT_REQUIRED: "Pago requerido",
    GET_CREDENTIAL_ERROR: "Ocurrió un error al obtener las credenciales, intente nuevamente",
    EXPIRED_TOKEN_ERROR: "El token de autorización ha expirado o es inválido",
    WAIT_GET_CREDENTIALS_PROCESS: "Consultando si cuenta con movimientos previamente descargados, por favor no recargue la página",
    SUCCESS_GET_MOVEMENTS: "Su consulta de movimientos ha sido exitosa",
    NOT_FOUND_MOVEMENTS: "No se encontraron movimientos previamente descargados, por favor sincronice sus cuentas"
}

