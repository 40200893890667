import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch, connect} from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "./Config";
import { InteractionStatus, InteractionRequiredAuthError  } from "@azure/msal-browser";

const Login = ({onLogin}) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { inProgress } = useMsal();

  const accounts = instance.getAllAccounts();

  useEffect(() => {
    if (accounts.length > 0) {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {
            onLogin(`Bearer ${response.accessToken}`);
            navigate("/home");
        })
        .catch((error) => {
            console.error(error);
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                if (inProgress === InteractionStatus.None){
                    const redirectRequest = {
                        ...loginRequest,
                    };

                    instance.acquireTokenRedirect(redirectRequest).then(function(response) {
                        onLogin(`Bearer ${response.accessToken}`);
                        navigate("/home");
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
                }
              }
        });
      }

      instance.handleRedirectPromise().then((response) => {
        if (!response) {
            if (accounts.length === 0 && inProgress === InteractionStatus.None) {
              instance.loginRedirect(loginRequest);
            }
        } 
      })
      .catch((err) => {
          console.error(err);
      });

  }, [accounts, instance, dispatch, navigate]);

    return <></>
};

const mapStateToProps = (state) => ({
    accessToken: state.OpenBankingReducer.accessToken,
});
  
const mapDispatchToProps = (dispatch) => ({
    onLogin: (accessToken) => {
        dispatch({type: 'ON_LOGIN', accessToken: accessToken});
    },
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);
