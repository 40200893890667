import { Button } from "antd";
import * as XLSX from 'xlsx'

export const ExportExcelButton = ({ dataSource, columns, filename }) => {
    const handleExport = () => {
      const worksheet = XLSX.utils.json_to_sheet(dataSource.map(record => {
        return columns.reduce((obj, col) => {
          obj[col.title] = record[col.dataIndex];
          return obj;
        }, {});
      }));
  
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };
  
    return <Button style={{marginBottom: '1rem'}} onClick={handleExport} type="primary">Exportar datos</Button>;
  };