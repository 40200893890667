export const GET_BANK_CATALOG_REQUEST = "GET_BANK_CATALOG_REQUEST";
export const GET_BANK_CATALOG_SUCCESS = "GET_BANK_CATALOG_SUCCESS";
export const GET_BANK_CATALOG_FAILURE = "GET_BANK_CATALOG_FAILURE";

export const SET_BANK_PRODUCT_LIST_REQUEST = "SET_BANK_PRODUCT_LIST_REQUEST";
export const SET_DYNAMIC_CREDENTIALS_REQUEST = "SET_DYNAMIC_CREDENTIALS_REQUEST";

export const SHOW_TWOFA_CHALLENGE = "SHOW_TWOFA_CHALLENGE";
export const SET_TWOFA_CHALLENGE_REQUEST = "SET_TWOFA_CHALLENGE_REQUEST";
export const SET_TWOFA_CHALLENGE_BACK = "SET_TWOFA_CHALLENGE_BACK";
export const SET_TWOFA_CHALLENGE_SUCCESS = "SET_TWOFA_CHALLENGE_SUCCESS";
export const SET_TWOFA_CHALLENGE_FAILURE = "SET_TWOFA_CHALLENGE_FAILURE";

export const SHOW_TWOFA_OPTIONS_CHALLENGE = "SHOW_TWOFA_OPTIONS_CHALLENGE";
export const SET_TWOFA_CHALLENGE_OPTIONS_REQUEST = "SET_TWOFA_CHALLENGE_OPTIONS_REQUEST";

export const GET_CREDENTIALS_REQUEST = "GET_CREDENTIALS_REQUEST";
export const GET_CREDENTIALS_SUCCESS = "GET_CREDENTIALS_SUCCESS";
export const GET_CREDENTIALS_FAILURE = "GET_CREDENTIALS_FAILURE";

export const SET_CREDENTIALS_REQUEST = "SET_CREDENTIALS_REQUEST";
export const SET_CREDENTIALS_SUCCESS = "SET_CREDENTIALS_SUCCESS";
export const SET_CREDENTIALS_FAILURE = "SET_CREDENTIALS_FAILURE";

export const GET_ACCOUNTS_REQUEST = "GET_ACCOUNTS_REQUEST";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_ACCOUNTS_FAILURE = "GET_ACCOUNTS_FAILURE";

export const SHOW_MOVEMENT_FORM = "SHOW_MOVEMENT_FORM";
export const SHOW_CREDENTIALS_FORM = "SHOW_CREDENTIALS_FORM";
export const SHOW_BANK_FORM = "SHOW_BANK_FORM";

export const GET_MOVEMENTS_REQUEST = "GET_MOVEMENTS_REQUEST";
export const GET_MOVEMENTS_SUCCESS = "GET_MOVEMENTS_SUCCESS";
export const GET_MOVEMENTS_FAILURE = "GET_MOVEMENTS_FAILURE";

export const SET_SELECTED_PRODUCT_ID = "SET_SELECTED_PRODUCT_ID";
export const INIT_VALUES_ACCOUNT_MOVEMENT_FORM = "INIT_VALUES_ACCOUNT_MOVEMENT_FORM";

export const ON_LOGIN = "ON_LOGIN";
