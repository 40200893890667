

import { Button, Col, Form, Image, Input, Row, Space, Spin, Typography, Radio } from 'antd';
import React from 'react'
import { useState } from 'react';
import { connect } from 'react-redux';
import { msalInstance } from "../../auth/Config";

export const TwofaOptionsForm = ({
    licence, 
    bankProductId, 
    twofaOptionsChallenge, 
    credentialId, 
    onBackTwofa, 
    onShowMovementForm,
    onSetTwofaChallenge,
    twofaOptionsInProgress,
    setTwofaSelectOptionLoader, 
    setTwofaOptions,
    showTwofaSelectOptions,
    showTwofaOptions}) => {

    const [formTwofaOptions] = Form.useForm();

    const [twofaOptionValue, setTwofaOptionValue] = useState(null);
    const [twofaOptionSubmit, setTwofaOptionSubmit] = useState(false);
    const [twofaTokenSubmit, setTwofaTokenSubmit] = useState(false);

    const handleTwofaSelectOption = (option) => {
        setTwofaOptionValue(option.target.value);
    };

    const handleBack = () => {
        onBackTwofa();
    }

    const useGetLicenseId = () => {
        const account = msalInstance.getAllAccounts()[0];
        var claims = account.idTokenClaims;
        return `${claims.extension_LicenseID}`;
    }
    licence = useGetLicenseId();

    const handleTwofaOptions = () => {
        let request = {
            licence: licence,
            bankProductId: bankProductId,
            credentialId: credentialId
        };

        const twofaToken = formTwofaOptions.getFieldsValue().twofaOptionsToken;
        if(twofaToken !== null && twofaToken !== undefined){
            request["nameToken"] = "token";
            request["token"] = twofaToken;
            setTwofaTokenSubmit(true);
        } 
        else{
            request["nameToken"] = twofaOptionsChallenge.name;
            request["authentication_methods"] = twofaOptionValue;
        }

        setTwofaOptionSubmit(true);
        onSetTwofaChallenge(request)
    }
        
  return (
    <>
        <Form form={formTwofaOptions} layout="vertical" onFinish={handleTwofaOptions} >
            {showTwofaSelectOptions && (
                <Spin spinning={setTwofaSelectOptionLoader}>
                    <Form.Item>
                        <Row justify='space-around'>
                            <Col>
                                <Typography.Title level={4}>{twofaOptionsChallenge.label}</Typography.Title>
                            </Col>
                        </Row>
                        <Row justify={"space-around"}>
                            <Radio.Group onChange={handleTwofaSelectOption} value={twofaOptionValue} buttonStyle="solid">
                                <Space direction="vertical">
                                    {
                                        twofaOptionsChallenge.options.map(option => (
                                            <Radio.Button value={option.value}>{option.label}</Radio.Button>
                                        ))
                                    }
                                </Space>
                            </Radio.Group>
                        </Row>
                        <Row justify={"space-around"} style={{ paddingTop: '10px' }}>
                            <Form.Item>
                                <Space>
                                    <Button type="default" onClick={handleBack}>Regresar</Button>
                                    <Button type="primary" htmlType="submit">Continuar</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Form.Item>
                </Spin>
            )}

            {showTwofaOptions && (
                <>
                    <Spin spinning={twofaTokenSubmit}>
                        {setTwofaOptions && twofaOptionSubmit && (
                            <Form.Item>
                                <Row justify='space-around'>
                                    <Col>
                                        <Typography.Title level={5}>{setTwofaOptions.label}</Typography.Title>
                                    </Col>
                                </Row>
                            </Form.Item>
                        )}
                        {setTwofaOptions && setTwofaOptions.type === 'text' && twofaOptionSubmit && (
                            <Form.Item>
                                <Row justify={"space-around"}>
                                    <Form.Item rules={[{ required: true }]} required={true} name="twofaOptionsToken">
                                        <Input />
                                    </Form.Item>
                                </Row>
                                <Row justify={"space-around"}>
                                    <Form.Item>
                                        <Space>
                                            <Button type="default" onClick={handleBack}>Regresar</Button>
                                            <Button type="primary" htmlType="submit">Enviar</Button>
                                        </Space>
                                    </Form.Item>
                                </Row>
                            </Form.Item>
                        )}
                    </Spin>

                    {setTwofaOptions && setTwofaOptions.imgBase64File !== '' && twofaOptionsInProgress && (
                        <Form.Item>
                            <Row justify={"space-around"}>
                                <Form.Item name="twofaImage" justify='center'>
                                    <Image src={setTwofaOptions.imgBase64File} />
                                </Form.Item>
                            </Row>
                            <Row justify={"space-around"}>
                                <Form.Item>
                                    <Space>
                                        <Button type="default" onClick={handleBack}>Regresar</Button>
                                        <Button type="primary" onClick={() => onShowMovementForm(true)}>Visualizar Movimientos</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Form.Item>
                    )}
                </>
            )}
        </Form>
    </>
    )
}

const mapStateToProps = (state) => ({
    credentialId: state.OpenBankingReducer.credentialId,
    licence: state.OpenBankingReducer.licence,
    twofaOptionsInProgress: state.OpenBankingReducer.twofaOptionsInProgress,
    setTwofaSelectOptionLoader: state.OpenBankingReducer.setTwofaSelectOptionLoader,
    twofaOptionsChallenge: state.OpenBankingReducer.twofaOptionsChallenge,
    setTwofaOptions: state.OpenBankingReducer.setTwofaOptions,
    showTwofaSelectOptions: state.OpenBankingReducer.showTwofaSelectOptions,
    showTwofaOptions: state.OpenBankingReducer.showTwofaOptions
});

const mapDispatchToProps = (dispatch) => ({
    onBackTwofa: () => {
        dispatch({type: 'SET_TWOFA_CHALLENGE_BACK'});
    },
    onSetTwofaChallenge: (form) => {
        dispatch({type: 'SET_TWOFA_CHALLENGE_REQUEST', form});
    },    
    onShowMovementForm: (show) => {
        dispatch({type: 'SHOW_MOVEMENT_FORM', showMovementForm: show, licence: ''});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(TwofaOptionsForm);