

import { Button, Col, Form, Image, Input, Row, Space, Spin, Typography } from 'antd';
import React from 'react'
import { connect } from 'react-redux';
import { msalInstance } from "../../auth/Config";

export const TwofaForm = ({
    licence, 
    bankProductId, 
    twofaChallenge, 
    credentialId, 
    setTwofaLoader, 
    onBackTwofa, 
    onShowMovementForm,
    onSetTwofaChallenge}) => {

    const [formTwofa] = Form.useForm();

    const handleBack = () => {
        onBackTwofa();
    }

    const useGetLicenseId = () => {
        const account = msalInstance.getAllAccounts()[0];
        var claims = account.idTokenClaims;
        return `${claims.extension_LicenseID}`;
    }
    licence = useGetLicenseId();

    const handleTwofa = (values) => {
        let request = {
            nameToken: twofaChallenge.name,
            licence: licence,
            bankProductId: bankProductId,
            credentialId: credentialId,
            token: formTwofa.getFieldsValue().twofaToken
        };
        onSetTwofaChallenge(request)
    }

  return (
    <>
        <Row justify='center'>
            <Col>
                <Typography.Title level={4}>Ingresa tu doble factor</Typography.Title>
            </Col>
        </Row>
        <Spin spinning={setTwofaLoader}>
            <Form form={formTwofa} layout="vertical" onFinish={handleTwofa} >
                    
                    {twofaChallenge.imgBase64File !== '' && (
                    <Row justify={"space-around"}>
                        <Form.Item name="twofaImage" justify='center'>
                            <Image src={twofaChallenge.imgBase64File} />
                        </Form.Item>
                    </Row>
                    )}

                    {twofaChallenge.type === 'text' && (
                        <Row justify={"space-around"}>
                            <Form.Item label={twofaChallenge.label} rules={[{ required: true }]} required={true} name="twofaToken">
                                <Input />
                            </Form.Item>
                        </Row>
                    )}
                    
                {twofaChallenge.type === 'text' && (
                    <Row justify={"space-around"}>
                        <Form.Item>
                            <Space>
                                <Button type="default" onClick={handleBack}>Regresar</Button>
                                <Button type="primary" htmlType="submit">Enviar</Button>
                            </Space>
                        </Form.Item>
                    </Row>
                )}

                {twofaChallenge.type !== 'text' && (
                    <Row justify={"space-around"}>
                        <Form.Item>
                            <Space>
                                <Button type="default" onClick={handleBack}>Regresar</Button>
                                <Button type="primary" onClick={() => onShowMovementForm(true)}>Visualizar Movimientos</Button>
                            </Space>
                        </Form.Item>
                    </Row>
                )}
            </Form>
        </Spin>
        
    </>
    )
}

const mapStateToProps = (state) => ({
    credentialId: state.OpenBankingReducer.credentialId,
    licence: state.OpenBankingReducer.licence,
    setTwofaLoader: state.OpenBankingReducer.setTwofaLoader
});

const mapDispatchToProps = (dispatch) => ({
    onBackTwofa: () => {
        dispatch({type: 'SET_TWOFA_CHALLENGE_BACK'});
    },
    onSetTwofaChallenge: (form) => {
        dispatch({type: 'SET_TWOFA_CHALLENGE_REQUEST', form});
    },    
    onShowMovementForm: (show) => {
        dispatch({type: 'SHOW_MOVEMENT_FORM', showMovementForm: show, licence: ''});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(TwofaForm);