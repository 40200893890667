import { msalInstance } from "../auth/Config";

export function Logout() {
  const LogoutHandler = async() => {
    localStorage.clear();
    sessionStorage.clear();

    msalInstance.logoutRedirect();
  }

  return (
    <a type="link"
      style={{textDecorationLine: 'underline'}}
      onClick={LogoutHandler}>
        Cerrar sesión
    </a>
  );
}