import * as OpenBankingActions from '../actions/OpenBankingActions';

const initialState = {
    bankList: [],
    bankNameList: [],
    bankProductList: [],
    credentials: [],
    bankAccountList: [],
    bankAccountMovementList: [],

    twofaChallenge: null,
    licence: '',
    bankProductId: null,
    bankAccountId: null,
    movementInitDate: null,
    movementEndDate: null,
    credentialId: null,

    fetchBankAccount: false,
    fetchBankCatalog: false,
    getCredentialLoader: false,
    setCredentialLoader: false,
    setTwofaLoader: false,

    twofaOptionsChallenge: null,
    setTwofaOptions: {},
    twofaOptionsInProgress: false,
    setTwofaSelectOptionLoader: false,
    hideCredentialForm: false,
    showTwofaSelectOptions: false,
    showTwofaOptions: false,
    
    showMovementForm: false,
    showCredentialsForm: false,
    showBankForm: true,

    accessToken: ""
}

const OpenBankingReducer = (state = initialState, action) => {
    switch (action.type) {
        case OpenBankingActions.GET_BANK_CATALOG_REQUEST:
            return { ...state, fetchBankCatalog: true }
        case OpenBankingActions.GET_BANK_CATALOG_SUCCESS:
            return { ...state, fetchBankCatalog: false, bankList: action.bankList, bankNameList: action.bankNameList }
        case OpenBankingActions.GET_BANK_CATALOG_FAILURE:
            return { ...state, fetchBankCatalog: false, bankList: action.bankList }
        case OpenBankingActions.SET_BANK_PRODUCT_LIST_REQUEST:
            return { ...state, bankProductList: action.bankProductList }
        case OpenBankingActions.SET_DYNAMIC_CREDENTIALS_REQUEST:
            return { ...state, credentials: action.credentials }

        case OpenBankingActions.GET_CREDENTIALS_REQUEST:
            return { ...state, getCredentialLoader: true, licence: action.licence, bankProductId: action.bankProductId }
        case OpenBankingActions.GET_CREDENTIALS_SUCCESS:
            return { ...state, getCredentialLoader: false, credentialId: action.credentialId }
        case OpenBankingActions.GET_CREDENTIALS_FAILURE:
            return { ...state, getCredentialLoader: false,  }
        
        case OpenBankingActions.SET_CREDENTIALS_REQUEST:
            return { ...state, setCredentialLoader: true }
        case OpenBankingActions.SET_CREDENTIALS_SUCCESS:
            return { ...state, setCredentialLoader: false, credentialId: action.credentialId }
        case OpenBankingActions.SET_CREDENTIALS_FAILURE:
            return { ...state, setCredentialLoader: false,  }
        case OpenBankingActions.GET_ACCOUNTS_REQUEST:
            return { ...state, fetchBankAccount: true, licence: action.licence, bankProductId: action.bankProductId, bankAccountMovementList: [] }
        case OpenBankingActions.GET_ACCOUNTS_SUCCESS:
            return { ...state, fetchBankAccount: false, bankAccountList: action.bankAccountList }
        case OpenBankingActions.GET_ACCOUNTS_FAILURE:
            return { ...state, fetchBankAccount: false, bankAccountList: [] }
        case OpenBankingActions.SHOW_MOVEMENT_FORM:
            return {
                 ...state,
                showMovementForm: action.showMovementForm, 
                showCredentialsForm: !action.showMovementForm, 
                showBankForm: !action.showMovementForm,
                licence: action.licence
            }
        case OpenBankingActions.SHOW_CREDENTIALS_FORM:
            return {
                ...state, 
                showCredentialsForm: action.showCredentialsForm, 
                showMovementForm: !action.showCredentialsForm, 
                showBankForm: !action.showCredentialsForm,
                setCredentialLoader: false
            }
        case OpenBankingActions.SHOW_BANK_FORM:
            return { 
                ...state, 
                showBankForm: action.showBankForm, 
                showCredentialsForm: !action.showBankForm, 
                showMovementForm: !action.showBankForm 
            }
        case OpenBankingActions.SHOW_TWOFA_CHALLENGE:
            return { 
                ...state, 
                twofaChallenge: action.twofaChallenge, 
                credentialId: action.credentialId, 
                licence: action.licence 
            }
        case OpenBankingActions.SHOW_TWOFA_OPTIONS_CHALLENGE:
            return { 
                ...state, 
                twofaOptionsChallenge: action.twofaOptionsChallenge, 
                credentialId: action.credentialId, 
                licence: action.licence,
                hideCredentialForm: true, 
                showTwofaSelectOptions: true
            }
        case OpenBankingActions.GET_MOVEMENTS_REQUEST:
            return { ...state, fetchBankAccount: true}
        case OpenBankingActions.GET_MOVEMENTS_SUCCESS:
            return { ...state, bankAccountMovementList: action.bankAccountMovementList, fetchBankAccount: false}
        case OpenBankingActions.GET_MOVEMENTS_FAILURE:
            return { ...state, fetchBankAccount: false}
        case OpenBankingActions.SET_TWOFA_CHALLENGE_REQUEST:
            return { ...state, setTwofaLoader: true, setTwofaSelectOptionLoader: true }
        case OpenBankingActions.SET_TWOFA_CHALLENGE_SUCCESS:
            return { 
                ...state, 
                setTwofaLoader: false, 
                twofaOptionsChallenge: null, 
                setTwofaOptions: {}, 
                setTwofaSelectOptionLoader: false, 
                twofaOptionsInProgress: false,
                hideCredentialForm: false,
                showTwofaSelectOptions: false,
                showTwofaOptions: false
            }
        case OpenBankingActions.SET_TWOFA_CHALLENGE_FAILURE:
            return { 
                ...state, 
                setTwofaLoader: false,
                twofaChallenge: null, 
                setCredentialLoader: false, 
                twofaOptionsChallenge: null, 
                setTwofaOptions: {}, 
                setTwofaSelectOptionLoader: false, 
                twofaOptionsInProgress: false,
                hideCredentialForm: false,
                showTwofaSelectOptions: false,
                showTwofaOptions: false
            }
        case OpenBankingActions.SET_TWOFA_CHALLENGE_OPTIONS_REQUEST:
            return { ...state, 
                setTwofaLoader: false, 
                setTwofaOptions: action.setTwofaOptions, 
                twofaOptionsInProgress: true,
                showTwofaSelectOptions: false,
                showTwofaOptions: true
            }
        case OpenBankingActions.SET_TWOFA_CHALLENGE_BACK:
            return { ...state, 
                setCredentialLoader: false, 
                twofaChallenge: null, 
                twofaOptionsChallenge: null, 
                setTwofaOptions: {}, 
                setTwofaSelectOptionLoader: false, 
                twofaOptionsInProgress: false,
                hideCredentialForm: false,
                showTwofaSelectOptions: false,
                showTwofaOptions: false
            }
        case OpenBankingActions.SET_SELECTED_PRODUCT_ID:
            return { ...state, bankProductId: action.bankProductId }
        case OpenBankingActions.INIT_VALUES_ACCOUNT_MOVEMENT_FORM:
            return { 
                ...state,
                bankAccountList: [],
                bankAccountMovementList: [],
                licence: '',
                twofaChallenge: null,
                twofaOptionsChallenge: null,
                hideCredentialForm: false,
                showTwofaSelectOptions: false,
                showTwofaOptions: false
             }
        case OpenBankingActions.ON_LOGIN:
            return { ...state, accessToken: action.accessToken }
        default:
            return state
    }
}

export default OpenBankingReducer;