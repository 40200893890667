import { PublicClientApplication } from "@azure/msal-browser";

const cliend_id = process.env.REACT_APP_B2C_CLIENT_ID ?? window._env_.REACT_APP_B2C_CLIENT_ID;
const domain_authority = process.env.REACT_APP_B2C_DOMAIN_AUTHORITY ?? window._env_.REACT_APP_B2C_DOMAIN_AUTHORITY;
const redirect_url = process.env.REACT_APP_B2C_REDIRECT_URL ?? window._env_.REACT_APP_B2C_REDIRECT_URL;
const session_scope = process.env.REACT_APP_B2C_SESSION_SCOPE ?? window._env_.REACT_APP_B2C_SESSION_SCOPE;
const signup_signin_authority = process.env.REACT_APP_B2C_SIGNUP_SIGNIN_AUTHORITY ?? window._env_.REACT_APP_B2C_SIGNUP_SIGNIN_AUTHORITY;

const post_logout_redirect_uri = signup_signin_authority + "/oauth2/v2.0/logout?post_logout_redirect_uri=" + redirect_url

const msalConfig = {
    auth: {
        clientId: cliend_id,
        authority: signup_signin_authority,
        knownAuthorities: [domain_authority],
        redirectUri: redirect_url,
        postLogoutRedirectUri: post_logout_redirect_uri
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" to save cache in cookies
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    scopes: ["openid", "offline_access", session_scope]
};