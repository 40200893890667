import axios from 'axios';
import {apiCrypto} from '../endpoints';

const endpoint = apiCrypto;

export const getSigningKey = async (accessToken) => {
    const headers = {
        'Authorization': accessToken,
    };
    return axios.get(`${endpoint}api/v1/Secrets/MasterKeys/0`, {timeout: 20000, headers: headers})
    .then((response) => {
        return response;
    })
    .catch((error) => {
        return error;
    });
}
  