import React, {useEffect} from 'react';
import { Image, notification } from 'antd';
import { connect } from 'react-redux';
import { ReactComponent as WarningIcon } from '../assets/svg/warning.svg';
import { ReactComponent as ErrorIcon } from '../assets/svg/error.svg';
import { ReactComponent as InfoIcon } from '../assets/svg/info.svg';
import { ReactComponent as SuccessIcon } from '../assets/svg/success.svg';

const AlertMessage = (props) => {
    // const [messageApi, contextHolder] = message.useMessage();
    const [notificationApi, contextHolderNotification] = notification.useNotification();

    useEffect(() => {
        if (props.showSuccessMsg) {
            notificationApi.open({
                type: 'success',
                message: props.textTitle,
                description: props.textMessage,
                icon: <SuccessIcon/>,
                closeIcon: null
            });
            props.onShowSuccessMsg();
        } else if (props.showWarningMsg) {
            notificationApi.open({
                type: 'warning',
                message: props.textTitle,
                description: props.textMessage,
                icon: <WarningIcon/>,
                closeIcon: null
            });
            props.onShowWarningMsg();
        } else if (props.showErrorMsg) {
            notificationApi.open({
                type: 'error',
                message: props.textTitle,
                description: props.textMessage,
                icon: <ErrorIcon/>,
                closeIcon: null
            });
            props.onShowErrorMsg();
        } else if (props.showInfoMsg) {
            notificationApi.open({
                type: 'info',
                message: props.textTitle,
                description: props.textMessage,
                icon: <InfoIcon/>,
                closeIcon: null
            });
            props.onShowInfoMsg();
        }
    }, [props, notificationApi]);

    return <div> {contextHolderNotification} </div>;
}

const mapStateToProps = (state) => {
    return {
        showSuccessMsg: state.ConfigurationReducer.showSuccessMsg,
        showWarningMsg: state.ConfigurationReducer.showWarningMsg,
        showErrorMsg: state.ConfigurationReducer.showErrorMsg,
        showInfoMsg: state.ConfigurationReducer.showInfoMsg,
        textMessage: state.ConfigurationReducer.textMessage,
        textTitle: state.ConfigurationReducer.textTitle,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onShowSuccessMsg: () => {
            dispatch({ type: 'SHOW_SUCCESS_MSG', showSuccessMsg: false, textMessage: null, textTitle: null });
        },
        onShowWarningMsg: () => {
            dispatch({ type: 'SHOW_WARNING_MSG', showWarningMsg: false, textMessage: null, textTitle: null });
        },
        onShowErrorMsg: () => {
            dispatch({ type: 'SHOW_ERROR_MSG', showErrorMsg: false, textMessage: null, textTitle: null });
        },
        onShowInfoMsg: () => {
            dispatch({ type: 'SHOW_INFO_MSG', showInfoMsg: false, textMessage: null, textTitle: null });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);
