export const substractMonth = (month) => {
    const currentDate = new Date();
    let newMonth = currentDate.getMonth() - month;
    let newYear = currentDate.getFullYear();

    const lastMonthDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - month, 0).getDate();
    currentDate.setDate(Math.min(currentDate.getDate(), lastMonthDay));

    if (newMonth < 0) {
      newYear--;
      newMonth += 12;
    }

    const newDate = new Date(newYear, newMonth, currentDate.getDate());
    return newDate;
  }

export const formatDate = (dateString, format = 'ex-MX') => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString(format, options);
};