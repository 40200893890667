import "../App.css";
import { connect } from 'react-redux';
import { Logout } from "../auth/Logout";
import Login from "../auth/Login";
import Main from "../components/BankSelector/Main";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ProtectedRoute from "../routes/ProtectedRoute";

function OpenBankingApp() {
    return (
    <>
    <Router>
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/home" element={<ProtectedRoute><Main></Main></ProtectedRoute>} />
            <Route path="/*" element={<Login />} />
        </Routes>
    </Router>
    </>
    );
}

export default connect()(OpenBankingApp);