import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as OpenBankingActions from '../actions/OpenBankingActions';
import * as ConfigurationActions from '../actions/ConfigurationActions';
import { getBankAccounts, getBankCatalog, getMovements, getProcess, getCredentials, setCredentials, setTwofa } from '../../services/OpenBankingServices';
import { getSigningKey } from '../../services/CryptoService';
import { encryptAES, generateRandomKey, rsaOAEP256Encrypt } from '../../utils/encrypt/encrypt'
import { formatDate } from '../../utils/date/dateOps'
import { AppMessage } from '../../AppMessages'
import { getAccessToken } from '../reducers/selector';

const KEYLENGTH = 16

const isBetween = (value, min, max) => {
    return value >= min && value <= max;
};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Consulta continuamente el estatus del proceso ejecutado
 *
 * @param {string} processId - Id del proceso.
 * @returns {json} - respuesta del servicio, puede ser exitosa, fallida o solicitar un 2FA.
 * @example
 * // Ejemplo de uso de la función.
 * const resultado = await getProcessDelay(processId);
 */
function* getProcessDelay(processId, delayTime, timeout) {
    let responseBody = undefined;
    const initTime = Date.now();
    let i = 0;
    const showInfoMessageTIme = 10000;

    while (true) {

        i = i + 1;
        if (((i * delayTime) % showInfoMessageTIme) === 0)
            yield put({  type: ConfigurationActions.SHOW_INFO_MSG, showInfoMsg: true, textMessage: AppMessage.GET_PROCESS_CONTINUE, textTitle: 'Procesando' });

        if ((Date.now() - initTime) >= timeout){
            responseBody = {
                status: 504, 
                message: AppMessage.GET_PROCESS_TIMEOUT
            }
            break;
        }

        const accessToken = yield select(getAccessToken);
        const response = yield call(getProcess, processId, accessToken);
        if (response.status === 204){
            yield call(delay, delayTime);
            continue;
        }

        if (response.status === 200 && isBetween(response.data.code, 100, 103)) {
            yield call(delay, delayTime);
            continue;
        }
        if (response.status === 200 && isBetween(response.data.code, 200, 206)) {
            responseBody = {
                status: response.data.code, 
                message: AppMessage.SUCCESS_SYNC,
                credentialId: response.data.resultData.credentialId
            }
            break; 
        }

        if (response.status === 200 && response.data.code === 410) {
            const twofaChallenge = response.data.resultData.twofaChallenge;
            responseBody = {
                status: response.data.code, 
                message: AppMessage.WAITING_TWOFA,
                twofa: twofaChallenge,
                credentialId: response.data.resultData.credentialId
            }

            if(twofaChallenge === undefined || twofaChallenge === null){
                responseBody["twofaOptions"] = response.data.resultData;
            }

            break; 
        }

        if (response.status === 200 && response.data.code === 400) {
            responseBody = {
                status: response.data.code, 
                message: response.data.messageError
            }
            break; 
        }

        if (response.status === 200 && response.data.code === 411) {
            responseBody = {
                status: response.data.code, 
                message: AppMessage.TWOFA_TIMEOUT
            }
            break; 
        }

        if (response.status === 200 && (response.data.code === 401 || response.data.code === 403)) {
            responseBody = {
                status: response.data.code, 
                message: AppMessage.INVALID_CREDENTIALS
            }
            break; 
        }

        if (response.status === 200 && (isBetween(response.data.code, 405, 409))) {
            responseBody = {
                status: response.data.code, 
                message: AppMessage.USER_INTERACTION_REQUIRED
            }
            break; 
        }

        if (response.status === 200 && isBetween(response.data.code, 500, 509)) {
            responseBody = {
                status: response.data.code, 
                message: AppMessage.PROVIDER_ERROR
            }
            break; 
        }

        if (response.status === 402){
            responseBody = {
                status: response.status, 
                message: AppMessage.PAYMENT_REQUIRED
            }
            break; 
        }

        yield call(delay, delayTime);
    }
    return responseBody;
}

/**
 * Obtiene el catalogo de bancos
 *
 * @example
 * // Ejemplo de uso de la función.
 * const resultado = getProducts();
 */
function* getProducts() {
    try {
        const inCache = localStorage.getItem('banks');
        if (inCache){
            const parsedValue = JSON.parse(inCache);
            yield put({ type: OpenBankingActions.GET_BANK_CATALOG_SUCCESS, bankNameList: parsedValue});
            return;
        };

        const accessToken = yield select(getAccessToken);
        const response = yield call(getBankCatalog, accessToken);

        if (response.status === 200) {
            // Success
            const bankList = response.data;
            let uniqueNames = []
            bankList.forEach(bank => {
                if (uniqueNames.length === 0 || !uniqueNames.find(m=> m.name === bank.bankName))
                {
                    const filteredProducts = bankList.filter(m=> m.bankName === bank.bankName)
                    const groupedBank = filteredProducts.map(m=> ({product: m.productName, credentials: m.credentials, id: m.id}))
                    uniqueNames = [...uniqueNames, { name: bank.bankName, image: bank.bankSmallCoverLogoImageUrl, products: groupedBank }];
                }
            });

            localStorage.setItem('banks', JSON.stringify([...uniqueNames]))
            yield put({ type: OpenBankingActions.GET_BANK_CATALOG_SUCCESS, bankList, bankNameList: [...uniqueNames]});
        } 
        else if (response.status === 402){
            yield put({ type: OpenBankingActions.GET_BANK_CATALOG_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: AppMessage.PAYMENT_REQUIRED, textTitle: 'Error' });
        }
        else{
            // Bad Request - Server Error
            const showErrorMsg = true;
            const textTitle = 'Error';
            const textMessage = AppMessage.FETCH_PRODUCT_ERROR;

            const errorList = []

            yield put({ type: OpenBankingActions.GET_BANK_CATALOG_FAILURE, bankList: errorList });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage, textTitle });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: OpenBankingActions.GET_BANK_CATALOG_FAILURE, bankList: [] });
    }
}

export function* getProductsSaga() {
    yield takeLatest(OpenBankingActions.GET_BANK_CATALOG_REQUEST, getProducts);
}

function* getCredential(params) {
    try {
        const accessToken = yield select(getAccessToken);
        yield put({  type: ConfigurationActions.SHOW_INFO_MSG, showInfoMsg: true, textMessage: AppMessage.WAIT_GET_CREDENTIALS_PROCESS, textTitle: 'Procesando' });        

        const header = {
            'license': params.licence
        }

        const responseCredentials = yield call(getCredentials, header, accessToken);
        if (responseCredentials.status === 200) {
            const credential = responseCredentials.data.find(d => 
                d.userName === params.licence && 
                d.bankProductId === params.bankProductId && 
                d.isAuthorized);

            if(credential){
                yield put({ type: ConfigurationActions.SHOW_INFO_MSG, showInfoMsg: true, textMessage: AppMessage.SUCCESS_GET_MOVEMENTS, textTitle: 'Información' });
                yield put({ type: OpenBankingActions.GET_CREDENTIALS_SUCCESS, credentialId: credential.credentialId});
                yield put({ type: OpenBankingActions.SHOW_MOVEMENT_FORM, licence: params.licence, showMovementForm: true, showCredentialsForm: false, showBankForm: false });
            }
            else{
                yield put({ type: OpenBankingActions.GET_CREDENTIALS_FAILURE });
                yield put({ type: ConfigurationActions.SHOW_WARNING_MSG, showWarningMsg: true, textMessage: AppMessage.NOT_FOUND_MOVEMENTS, textTitle: 'Error' });    
            }
            
        }
        else if (responseCredentials.status === 401){
            const showErrorMsg = true;
            yield put({ type: OpenBankingActions.GET_CREDENTIALS_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage: AppMessage.EXPIRED_TOKEN_ERROR, textTitle: 'Error' });
        }
        else if (responseCredentials.status === 402){
            const showErrorMsg = true;
            yield put({ type: OpenBankingActions.GET_CREDENTIALS_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage: AppMessage.PAYMENT_REQUIRED, textTitle: 'Error' });
        }
        else if (responseCredentials.response.status === 404){
            const showErrorMsg = true;
            yield put({ type: OpenBankingActions.GET_CREDENTIALS_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage: AppMessage.NOT_FOUND_MOVEMENTS, textTitle: 'Error' });
        }
        else{
            const showErrorMsg = true;
            const textTitle = 'Error';
            const textMessage = AppMessage.GET_CREDENTIAL_ERROR;
            yield put({ type: OpenBankingActions.GET_CREDENTIALS_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage, textTitle });
            }
    } catch (error) {
        console.log(error);
        yield put({ type: OpenBankingActions.GET_CREDENTIALS_FAILURE });
    }
}

export function* getCredentialSaga() {
    yield takeLatest(OpenBankingActions.GET_CREDENTIALS_REQUEST, getCredential);
}

function* setCredential(params) {
    try {
        const labels = params.labels;
        const formValues = params.form;
        const objWithLicence = formValues.filter(obj => obj.hasOwnProperty('licence'))[0];
        const dynamicCredentials = formValues.filter(obj => !obj.hasOwnProperty('licence') && !obj.hasOwnProperty('productId'));

        if (objWithLicence.licence === undefined || objWithLicence.licence.trim() === ''){
            yield put({ type: OpenBankingActions.SET_CREDENTIALS_FAILURE });
            yield put({ type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: AppMessage.LICENCE_REQUIRED, textTitle: 'Error' });
            return;
        }

        for (const [index, credential] of dynamicCredentials.entries()) {
            for (const [key, value] of Object.entries(credential)){
                if (value === undefined || value.trim() === ''){
                    yield put({ type: OpenBankingActions.SET_CREDENTIALS_FAILURE });
                    yield put({ type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: AppMessage.PRODUCT_FIELD_REQUIRED.replace('{field}', labels[key]), textTitle: 'Error' });
                    return;
                }
            }
        }
        const accessToken = yield select(getAccessToken);
        yield put({  type: ConfigurationActions.SHOW_INFO_MSG, showInfoMsg: true, textMessage: AppMessage.WAIT_GET_PROCESS, textTitle: 'Procesando' });        
        const responseCrypto = yield call(getSigningKey, accessToken);

        if (responseCrypto.status === 200) {
            // Success
            const signingKey = responseCrypto.data.base64Key;
            const randomKey = generateRandomKey(KEYLENGTH);

            let transportKey = yield call(rsaOAEP256Encrypt, randomKey, signingKey);
            
            const objWithProductId = formValues.filter(obj => obj.hasOwnProperty('productId'))[0];
            const header = {
                'transportKey': transportKey,
                'productId': objWithProductId.productId,
                'license': objWithLicence.licence
            }

            let listValues = {}

            for (const [index, credential] of dynamicCredentials.entries()) {
                for (const [key, value] of Object.entries(credential)){
                    const encryptedValue = yield encryptAES(value, randomKey);
                    listValues[key] = encryptedValue;
                }
            }

            const body = {
                "CredentialData": listValues,
                "UserName": objWithLicence.licence
            };

            const responseCredentials = yield call(setCredentials, header, body, accessToken);
            if (responseCredentials.status === 201) {
                const processId = responseCredentials.data.id;
                const processResponse = yield call(getProcessDelay, processId, 2000, 120000);
                if (processResponse === undefined){
                    yield put({ type: OpenBankingActions.SET_CREDENTIALS_FAILURE});
                    yield put({ type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: AppMessage.GENERAL_ERROR, textTitle: 'Error' });
                }
                const credentialId = processResponse.credentialId;
                
                if (processResponse.status === 410){
                    if(processResponse.twofa.type === 'textOptions'){
                        yield put({ 
                            type: OpenBankingActions.SHOW_TWOFA_OPTIONS_CHALLENGE, 
                            twofaOptionsChallenge: processResponse.twofa, 
                            credentialId: credentialId, 
                            licence: objWithLicence.licence
                        });
                    }
                    else{
                        yield put({ type: OpenBankingActions.SHOW_TWOFA_CHALLENGE, twofaChallenge: processResponse.twofa, credentialId: credentialId, licence: objWithLicence.licence});
                    }

                    yield put({  type: ConfigurationActions.SHOW_INFO_MSG, showInfoMsg: true, textMessage: processResponse.message, textTitle: 'Autenticación' });
                }
                else if (processResponse.status === 402){
                    const showErrorMsg = true;
                    yield put({ type: OpenBankingActions.SET_CREDENTIALS_FAILURE });
                    yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage: AppMessage.PAYMENT_REQUIRED, textTitle: 'Error' });
                }
                else if (isBetween(processResponse.status, 200, 206)){
                    yield call(validateFullSynchronization, objWithProductId.productId, credentialId, accessToken);

                    yield put({ type: ConfigurationActions.SHOW_INFO_MSG, showInfoMsg: true, textMessage: AppMessage.SUCCESS_SYNC_VALIDATE_LATER, textTitle: 'Información' });
                    yield put({ type: OpenBankingActions.SET_CREDENTIALS_SUCCESS, credentialId: credentialId})
                    yield put({ type: OpenBankingActions.SHOW_MOVEMENT_FORM, licence: objWithLicence.licence, showMovementForm: true, showCredentialsForm: false, showBankForm: false });
                }
                else {
                    yield put({ type: OpenBankingActions.SET_CREDENTIALS_FAILURE});
                    yield put({ type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: processResponse.message, textTitle: 'Error' });
                }
            }
            else if (responseCredentials.status === 402){
                const showErrorMsg = true;
                yield put({ type: OpenBankingActions.SET_CREDENTIALS_FAILURE });
                yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage: AppMessage.PAYMENT_REQUIRED, textTitle: 'Error' });
            }
            else{
                const showErrorMsg = true;
                const textTitle = 'Error';
                const textMessage = AppMessage.CREDENTIAL_ERROR;
                yield put({ type: OpenBankingActions.SET_CREDENTIALS_FAILURE });
                yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage, textTitle });
                }
        } 
        else if (responseCrypto.status === 402){
            const showErrorMsg = true;
            yield put({ type: OpenBankingActions.SET_CREDENTIALS_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage: AppMessage.PAYMENT_REQUIRED, textTitle: 'Error' });
        }
        else{
            // Bad Request - Server Error
            const showErrorMsg = true;
            const textTitle = 'Error';
            const textMessage = AppMessage.ENCRYPT_ERROR;
            yield put({ type: OpenBankingActions.SET_CREDENTIALS_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage, textTitle });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: OpenBankingActions.SET_CREDENTIALS_FAILURE });
    }
}

export function* setCredentialSaga() {
    yield takeLatest(OpenBankingActions.SET_CREDENTIALS_REQUEST, setCredential);
}

function* validateFullSynchronization(productId, credentialId, accessToken) {
    try {
        const timeout = 60000;
        const delayTime = 3000;
        const initTime = Date.now();
    
        while (true) {
            yield call(delay, delayTime);

            const accountsResponse = yield call(getBankAccounts, productId, credentialId, accessToken);
            if(accountsResponse === undefined){
                continue;
            }

            if(accountsResponse.response !== undefined && !isBetween(accountsResponse.response.status, 200, 206)){
                continue;
            }
            else if(isBetween(accountsResponse.status, 200, 206) && accountsResponse.data.length > 0){
                break;
            }
    
            if ((Date.now() - initTime) >= timeout){
                //Si llega a timeout terminamos el ciclo
                break;
            }
        }
    } 
    catch (error) {
        console.log(error);
        yield put({ type: OpenBankingActions.SET_CREDENTIALS_FAILURE});
    }
}

/**
 * Obtiene el catalogo de bancos
 *
 * @example
 * // Ejemplo de uso de la función.
 * const resultado = getProducts();
 */
function* getAccountList(formValues) {
    try {
        if (formValues.licence === undefined || formValues.licence.trim() === ''){
            yield put({ type: OpenBankingActions.GET_ACCOUNTS_FAILURE });
            yield put({ type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: AppMessage.LICENCE_REQUIRED, textTitle: 'Error' });
            return;
        }

        const accessToken = yield select(getAccessToken);
        const response = yield call(getBankAccounts, formValues.bankProductId, formValues.credentialId, accessToken);
        if (response.status === 200) {
            // Success
            const bankAccountList = response.data;
            let bankAccounts = []
            bankAccountList.forEach(account => {
                bankAccounts = [...bankAccounts, {accountId: account.accountId, text: `${account.nameAccount} - *** ${account.numberAccount.slice(-4)}`}]
            })
            yield put({ type: OpenBankingActions.GET_ACCOUNTS_SUCCESS, bankAccountList: bankAccounts });
        } else if (response.status === 404){
            yield put({ type: OpenBankingActions.INIT_VALUES_ACCOUNT_MOVEMENT_FORM });
            yield put({ type: OpenBankingActions.GET_ACCOUNTS_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_INFO_MSG, showInfoMsg: true, textMessage: AppMessage.ACCOUNTS_NOT_FOUND, textTitle: 'Información' });
        } 
        else if (response.status === 402){
            const showErrorMsg = true;
            yield put({ type: OpenBankingActions.INIT_VALUES_ACCOUNT_MOVEMENT_FORM });
            yield put({ type: OpenBankingActions.GET_ACCOUNTS_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage: AppMessage.PAYMENT_REQUIRED, textTitle: 'Error' });
        }
        else{
            // Bad Request - Server Error
            const showErrorMsg = true;
            const textTitle = 'Error';
            const textMessage = AppMessage.FETCH_BANK_ACCOUNT_FAILED;

            yield put({ type: OpenBankingActions.INIT_VALUES_ACCOUNT_MOVEMENT_FORM });
            yield put({ type: OpenBankingActions.GET_ACCOUNTS_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage, textTitle });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: OpenBankingActions.GET_ACCOUNTS_FAILURE});
    }
}

export function* getAccountListSaga() {
    yield takeLatest(OpenBankingActions.GET_ACCOUNTS_REQUEST, getAccountList);
}


function* getMovementByAccount(formValues) {
    try {
        const accessToken = yield select(getAccessToken);
        const response = yield call(getMovements, formValues, accessToken);

        if (response.status === 200) {
            const newData = response.data.map(item => {
                return {
                    ...item,
                    transactionDate: formatDate(item.transactionDate),
                    refreshDate: formatDate(item.refreshDate)
                };
            });

            yield put({ type: OpenBankingActions.GET_MOVEMENTS_SUCCESS, bankAccountMovementList: newData});
        } else if(response.response !== null){
            const data = response.response.data;

            if (data !== null && data.status === 404)
            {
                yield put({ type: OpenBankingActions.GET_MOVEMENTS_SUCCESS, bankAccountMovementList: []});
                yield put({  type: ConfigurationActions.SHOW_INFO_MSG, showInfoMsg: true, textMessage: AppMessage.MOVEMENTS_NOT_FOUND, textTitle: 'Información' });
            }
            else{
                const showErrorMsg = true;
                const textTitle = 'Error';
                const textMessage = AppMessage.FETCH_BANK_MOVEMENT_FAILED;

                yield put({ type: OpenBankingActions.GET_MOVEMENTS_FAILURE });
                yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage, textTitle });
            }
        } 
        else if (response.status === 402){
            const showErrorMsg = true;
            yield put({ type: OpenBankingActions.GET_MOVEMENTS_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage: AppMessage.PAYMENT_REQUIRED, textTitle: 'Error' });
        }
        else{
            // Bad Request - Server Error
            const showErrorMsg = true;
            const textTitle = 'Error';
            const textMessage = AppMessage.FETCH_BANK_MOVEMENT_FAILED;

            yield put({ type: OpenBankingActions.GET_MOVEMENTS_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage, textTitle });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: OpenBankingActions.GET_MOVEMENTS_FAILURE});
    }
}

export function* getMovementByAccountSaga() {
    yield takeLatest(OpenBankingActions.GET_MOVEMENTS_REQUEST, getMovementByAccount);
}

function* setTwofaAuth(params) {
    try {
        const formValues = params.form;

        let bodyRequestValue = undefined;
        if(formValues.token !== undefined && formValues.token.trim() !== ''){
            bodyRequestValue = formValues.token;
        }
        else if(formValues.authentication_methods !== undefined && formValues.authentication_methods.trim() !== ''){
            bodyRequestValue = formValues.authentication_methods;
        }

        if (bodyRequestValue === undefined) {
            yield put({ type: OpenBankingActions.SET_TWOFA_CHALLENGE_FAILURE });
            yield put({ type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: AppMessage.TWOFA_REQUIRED, textTitle: 'Error' });
            return;
        }

        const header = {
            'license': formValues.licence,
            'credentialId': formValues.credentialId
        }

        const body = {
            [formValues.nameToken]: bodyRequestValue
        };

        yield put({  type: ConfigurationActions.SHOW_INFO_MSG, showInfoMsg: true, textMessage: AppMessage.WAIT_GET_PROCESS, textTitle: 'Procesando' });
        const accessToken = yield select(getAccessToken);
        const response = yield call(setTwofa, header, body, accessToken);

        if (response.status === 201) {
            const processId = response.data.id;
            const processResponse = yield call(getProcessDelay, processId, 5000, 600000);
            if (processResponse === undefined){
                yield put({ type: OpenBankingActions.SET_TWOFA_CHALLENGE_FAILURE});
                yield put({ type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: AppMessage.GENERAL_ERROR, textTitle: 'Error' });
            }
            
            if (processResponse.status === 200){
                yield call(validateFullSynchronization, formValues.bankProductId, formValues.credentialId, accessToken);
                yield put({ type: ConfigurationActions.SHOW_INFO_MSG, showInfoMsg: true, textMessage: AppMessage.SUCCESS_SYNC_VALIDATE_LATER, textTitle: 'Información' });
                yield put({ type: OpenBankingActions.SET_TWOFA_CHALLENGE_SUCCESS } );
                yield put({ type: OpenBankingActions.SHOW_MOVEMENT_FORM, licence: formValues.licence, showMovementForm: true, showCredentialsForm: false, showBankForm: false });
            }
            else if (processResponse.status === 402){
                const showErrorMsg = true;
                yield put({ type: OpenBankingActions.SET_TWOFA_CHALLENGE_FAILURE });
                yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage: AppMessage.PAYMENT_REQUIRED, textTitle: 'Error' });
            }
            else if (processResponse.status === 410){
                yield put({ type: ConfigurationActions.SHOW_INFO_MSG, showInfoMsg: true, textMessage: AppMessage.SUCCESS_SEND_TWOFA_CODE, textTitle: 'Información' });
                yield put({ type: OpenBankingActions.SET_TWOFA_CHALLENGE_OPTIONS_REQUEST, setTwofaOptions: processResponse.twofaOptions });
            }
            else {
                yield put({ type: OpenBankingActions.SET_TWOFA_CHALLENGE_FAILURE});
                yield put({ type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: processResponse.message, textTitle: 'Error' });
            }
        } 
        else if (response.status === 402){
            const showErrorMsg = true;
            yield put({ type: OpenBankingActions.SET_TWOFA_CHALLENGE_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage: AppMessage.PAYMENT_REQUIRED, textTitle: 'Error' });
        }
        else{
            // Bad Request - Server Error
            const showErrorMsg = true;
            const textTitle = response.title;
            const textMessage = response.detail;

            yield put({ type: OpenBankingActions.SET_TWOFA_CHALLENGE_FAILURE });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage, textTitle });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: OpenBankingActions.SET_TWOFA_CHALLENGE_FAILURE});
    }
}

export function* setTwofaAuthSaga() {
    yield takeLatest(OpenBankingActions.SET_TWOFA_CHALLENGE_REQUEST, setTwofaAuth);
}