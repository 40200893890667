import BankForm from "./BankForm";
import CredentialForm from "../Credentials/CredentialForm";
import { Spin} from "antd";
import { connect } from "react-redux";
import AccountSelector from "../Movements/AccountSelector";
import { useEffect } from "react";

const BankList = ({
    bankList,
    fetchBankCatalog,
    showBankForm,
    showCredentialsForm,
    showMovementForm,
    onFetchingBankProducts
}) => {

    useEffect(() => {
        if (bankList === undefined || bankList.length === 0)
        {
            onFetchingBankProducts()
        }
    }, [])
    
    return (
        <>
            {showBankForm && (
                <Spin spinning={fetchBankCatalog}>
                    <BankForm />
                </Spin>
                
            )}
            
            {
                (showCredentialsForm) && (
                    <CredentialForm />
                )
            }

            {
                showMovementForm && (
                    <AccountSelector/>
                )
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    bankList: state.OpenBankingReducer.bankList,
    fetchBankCatalog: state.OpenBankingReducer.fetchBankCatalog,
    showBankForm: state.OpenBankingReducer.showBankForm,
    showCredentialsForm: state.OpenBankingReducer.showCredentialsForm,
    showMovementForm: state.OpenBankingReducer.showMovementForm,
    accessToken: state.OpenBankingReducer.accessToken
});

const mapDispatchToProps = (dispatch) => ({
    onFetchingBankProducts: () => {
        dispatch({type: 'GET_BANK_CATALOG_REQUEST'});
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(BankList);