import React from 'react';
import { Navigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

const ProtectedRoute = ({ children }) => {
    const { accounts } = useMsal();
    if (accounts.length === 0) {
      return <Navigate to="/login" replace={true} />;
    }
  
    return children;
  };

export default ProtectedRoute;