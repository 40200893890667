import axios from 'axios';
import {apiUrl, functionUrl, subscription_key} from '../endpoints';
import {substractMonth} from '../utils/date/dateOps'

const endpoint = apiUrl;
const endpointFunction = functionUrl;

export const getBankCatalog = async (accessToken) => {
  const headers = {
      'Authorization': accessToken,
      'Ocp-Apim-Subscription-Key': subscription_key
    };

  return axios.get(`${endpoint}api/v1/catalogs/products?limit=1000&orderBy=bankName`, {
    headers: headers
  })
  .then((response) => {
      return response;
  })
  .catch((error) => {
      return error;
  });
}

export const getBankAccounts = async (bankProduct, credentialId, accessToken) => {
    const headers = {
        'Authorization': accessToken,
        'Ocp-Apim-Subscription-Key': subscription_key,
        'credentialId': credentialId
    };

    return axios.get(`${endpoint}api/v1/accounts/${bankProduct}?limit=1000`, {headers:headers})
    .then((response) => {
        return response;
    })
    .catch((error) => {
      console.log(error)
        return error;
    });
}

export const getMovements = async (params, accessToken) => {
  const form = params.form;
  const headers = {
      'Authorization': accessToken,
      'license': form.licence,
      'accountId': form.bankAccount,
      'Ocp-Apim-Subscription-Key': subscription_key
  };

  let urlFinal = `${endpoint}api/v1/movements`;
  if (form.initDate !== null && form.initDate !== undefined){
    urlFinal = `${urlFinal}?initialDate=${form.initDate}&finalDate=${form.endDate}&limit=5000`;
  }
  if (form.initDate === null || form.initDate === undefined){
    const threeMonthsAgo = substractMonth(3);
    let year = threeMonthsAgo.getFullYear();
    let month = String(threeMonthsAgo.getMonth() + 1).padStart(2, '0');
    let day = String(threeMonthsAgo.getDate()).padStart(2, '0');
    form.initDate = `${year}-${month}-${day}`;

    const currentDate = new Date();
    year = currentDate.getFullYear();
    month = String(currentDate.getMonth() + 1).padStart(2, '0');
    day = String(currentDate.getDate()).padStart(2, '0');
    form.endDate = `${year}-${month}-${day}`;
    urlFinal = `${urlFinal}?initialDate=${form.initDate}&finalDate=${form.endDate}&limit=5000`;
  }

  return axios.get(urlFinal, {headers:headers})
  .then((response) => {
      return response;
  })
  .catch((error) => {
    console.log(error)
      return error;
  });
}

export const getProcess = async (processId, accessToken) => {
  const headers = {
      'Authorization': accessToken,
      'Ocp-Apim-Subscription-Key': subscription_key
  };

  return axios.get(`${endpointFunction}api/v1/process/${processId}`, {headers:headers})
  .then((response) => {
      console.log('response getProcess', response);
      return response;
  })
  .catch((error) => {
      console.log('error getProcess', error);
      return error;
  });
}

export const getCredentials = async (headers, accessToken) => {
  headers['Authorization'] = accessToken;
  headers['Ocp-Apim-Subscription-Key'] = subscription_key;

  return axios.get(`${endpoint}api/v1/credentials`, {
    headers: headers
  })
  .then((response) => {
    console.log('response get credentials', response);
    return response;
  })
  .catch((error) => {
    console.log('error get credentials', error);
    return error;
  });
}

export const setCredentials = async (headers, body, accessToken) => {
  headers['Authorization'] = accessToken;
  headers['Ocp-Apim-Subscription-Key'] = subscription_key;

  return axios.post(`${endpoint}api/v1/credentials/set`, body, {
    headers: headers
  })
  .then((response) => {
    console.log('response set credentials', response);
    return response;
  })
  .catch((error) => {
      console.log('error set credentials', error);
      return error;
  });
}

export const setTwofa = async (headers, body, accessToken) => {
  headers['Authorization'] = accessToken;
  headers['Ocp-Apim-Subscription-Key'] = subscription_key;

  return axios.post(`${endpoint}api/v1/credentials/set-twofactor`, body, {
    headers: headers
  })
  .then((response) => {
    console.log('response setTwofa', response);
    return response;
  })
  .catch((error) => {
      console.log('error setTwofa', error);
      return error;
  });
}