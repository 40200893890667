import Alert from '../../containers/Alert';
import { connect } from 'react-redux';
import { Logout } from "../../auth/Logout";
import logonew from '../../assets/images/logonew.png';
import { Row, Col, Image, Typography } from "antd";
import BankList from './BankList'

function Main() {
    return (
        <>
            <Row justify='center' gutter={[0,0]}>
                <Col span={23} className='cardApp'>
                    <Row justify={'center'} gutter={[4,8]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <Row justify='center'>
                                <Col>
                                    <Image src={logonew} preview={false}/>
                                </Col>
                            </Row>
                            <Row justify='center'>
                                <Col>
                                    <Typography.Title level={2}>Consulta de movimientos bancarios</Typography.Title>
                                </Col>
                            </Row>
                            <BankList/>
                            <Row justify={"space-around"}>
                                <Col>
                                    <Logout />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Alert/>
            </Row>
        </>
    );
}

export default connect()(Main);