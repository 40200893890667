import { Row, DatePicker, Form, Select, Input, Divider, Table, Space, Button, Spin, Modal} from "antd"
import { connect } from "react-redux";
import { ExportExcelButton } from '../Export/ExportExcelButton'
import moment from 'moment';
import { msalInstance } from "../../auth/Config";
import { useEffect, useState } from "react";

const { RangePicker } = DatePicker;

const AccountSelector = ({
    bankAccountList,
    bankAccountMovementList,
    bankProductId, 
    licence,
    credentialId,
    fetchBankAccount,
    onShowBankForm,
    onFetchBankAccounts,
    onFetchBankMovements,
    initValuesAccountMovementForm
}) => {

    const [formAccounts] = Form.useForm();
    const [formMovement] = Form.useForm();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const useGetLicenseId = () => {
        const account = msalInstance.getAllAccounts()[0];
        var claims = account.idTokenClaims;
        return `${claims.extension_LicenseID}`;
    }
    licence = useGetLicenseId();

    useEffect(() => {
        onFetchBankAccounts(licence, bankProductId, credentialId);

    }, [licence]);

    const getColumnSorter = (dataIndex, sortOrder) => (a, b) => {
        const aValue = a[dataIndex];
        const bValue = b[dataIndex];
      
        if (aValue == null || bValue == null) {
          return sortOrder === 'ascend' ? 1 : sortOrder === 'descend' ? -1 : 0;
        }
      
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return (aValue - bValue) * (sortOrder === 'descend' ? -1 : 1);
        } else {
          const aString = String(aValue).toLowerCase();
          const bString = String(bValue).toLowerCase();
          return aString.localeCompare(bString) * (sortOrder === 'descend' ? -1 : 1);
        }
      };

    const getDateSorter = (dataIndex, sortOrder) => (a, b) => {
        const aValue = a[dataIndex];
        const bValue = b[dataIndex];
      
        if (aValue == null || bValue == null) {
          return sortOrder === 'ascend' ? 1 : sortOrder === 'descend' ? -1 : 0;
        }

        const parseDate = (dateString) => {
          const [day, month, year] = dateString.split('/');
          return new Date(`${year}-${month}-${day}`);
        };
      
        const aDate = parseDate(aValue);
        const bDate = parseDate(bValue);

        return aDate.getTime() - bDate.getTime() * (sortOrder === 'descend' ? -1 : 1);
      };

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'transactionDate',
            key: 'transactionDate',
            sorter: getDateSorter('transactionDate')
        },
        {
            title: 'Concepto',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Importe',
            dataIndex: 'amount',
            key: 'amount',
            sorter: getColumnSorter('amount')
        },
        {
            title: 'Moneda',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'Detalle',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (rowData) => <Button type="primary" onClick={() => showDetailsModal(rowData)}>Ver</Button>,
        },
    ]

    const modalColumns = [
        {
            title: 'Concepto',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Descripción',
            dataIndex: 'extra',
            key: 'extra',
        },
    ]

    const showDetailsModal = (rowData) => {
        let details = [];
        const extraInfo = rowData.extra;
        if(extraInfo !== null && extraInfo !== undefined){
            Object.entries(extraInfo).forEach(([name, value]) => {
                details = [...details, {["name"]: name, ["extra"]: value}];
            });
        }

        setModalData(details);
        setModalOpen(true);
    }

    const fetchMovementsByBankAccount = () => {
        const formValues = formMovement.getFieldsValue();
        let request = {}
        if (formValues.bankAccount === undefined)
            return;
        if (formValues.dateRange !== null && formValues.dateRange !== undefined)
        {
            request.initDate = formValues.dateRange[0].format('YYYY-MM-DD');
            request.endDate = formValues.dateRange[1].format('YYYY-MM-DD');
        }
        request.bankAccount = formValues.bankAccount;
        request.licence = licence;
        onFetchBankMovements(request);
    }

    const goBack = () => {
        initValuesAccountMovementForm();
        onShowBankForm(true);
        formAccounts.resetFields();
        formMovement.resetFields();
    }

    const disableFuture = (current) => {
        return current && current > moment().endOf('day');
    }

    return (
        <>
            <Spin spinning={fetchBankAccount}>
                {
                    (bankAccountList.length > 0) && (
                        <>
                            <Divider/>
                                <Form form={formMovement} layout="vertical">
                                    <Form.Item
                                        name="dateRange"
                                        label="Seleccione un rango de fecha"
                                        rules={[
                                        { type: 'array', required: false }
                                        ]}
                                    >
                                        <RangePicker onChange={fetchMovementsByBankAccount} disabledDate={disableFuture}/>
                                    </Form.Item>
                                    <Form.Item label="Selecciona una cuenta" required={true} name="bankAccount">
                                        <Select placeholder="Selecciona una cuenta" onChange={fetchMovementsByBankAccount}>
                                        {
                                            bankAccountList.map(option => (
                                                <Select.Option key={option.accountId} value={option.accountId}>{option.text}</Select.Option>
                                            ))
                                        }
                                        </Select>
                                    </Form.Item>
                                </Form>
                                {
                                    (bankAccountMovementList.length > 0) && (
                                        <Row justify='end'>
                                            <ExportExcelButton dataSource={bankAccountMovementList} columns={columns} filename="movimientos" />
                                        </Row>
                                    )
                                }
                                
                                <Table 
                                    dataSource={bankAccountMovementList} 
                                    columns={columns} 
                                    locale={{emptyText: "No se encontraron movimientos en la cuenta seleccionada"}}
                                    pagination={{ pageSize: 10 }}
                                    />

                                <Modal
                                    title="Detalles del movimiento"
                                    centered
                                    open={modalOpen}
                                    destroyOnClose={true}
                                    onCancel={() => setModalOpen(false)}
                                    footer={[
                                        <Row justify={"space-around"}>
                                            <Form.Item>
                                                <Space>
                                                    <Button type="primary" onClick={() => setModalOpen(false)}>Aceptar</Button>
                                                </Space>
                                            </Form.Item>
                                        </Row>
                                    ]}
                                >
                                    <Table 
                                        dataSource={modalData} 
                                        columns={modalColumns} 
                                        locale={{emptyText: "No se encontró información adicional para el movimiento seleccionado"}}
                                        pagination={{ pageSize: 6, position: ["bottomCenter"]}}
                                    />
                                </Modal>
                        </>
                    )
                }
                <Row justify={"space-around"} style={{ paddingTop: '15px' }}>
                    <Form.Item>
                        <Space>
                            <Button type="default" onClick={goBack}>Regresar</Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Spin>  
        </>
    )
}

const mapStateToProps = (state) => ({
    bankProductId: state.OpenBankingReducer.bankProductId,
    licence: state.OpenBankingReducer.licence,
    fetchBankAccount: state.OpenBankingReducer.fetchBankAccount,
    bankAccountList: state.OpenBankingReducer.bankAccountList,
    bankAccountMovementList: state.OpenBankingReducer.bankAccountMovementList,
    credentialId: state.OpenBankingReducer.credentialId,
});

const mapDispatchToProps = (dispatch) => ({
    onShowBankForm: (show) => {
        dispatch({type: 'SHOW_BANK_FORM', showBankForm: show});
    },
    onFetchBankAccounts: (licence, bankProductId, credentialId) => {
        dispatch({type: 'GET_ACCOUNTS_REQUEST', licence: licence, bankProductId: bankProductId, credentialId: credentialId});
    },
    onFetchBankMovements: (form) => {
        dispatch({type: 'GET_MOVEMENTS_REQUEST', form});
    },
    initValuesAccountMovementForm: () => {
        dispatch({type: 'INIT_VALUES_ACCOUNT_MOVEMENT_FORM'});
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelector);