
export const generateRandomKey = (length) => {
    const buffer = new Uint8Array(length);
    window.crypto.getRandomValues(buffer);
    return Array.from(buffer).map(byte => byte.toString(16).padStart(2, '0')).join('');
}

export const encryptAES = async (toEncrypt, key) => {
    // Convertir la clave a un formato adecuado
    const dataToEncrypt = new TextEncoder().encode(toEncrypt);
    const keyEncoded = new TextEncoder().encode(key);
    const importKey = await window.crypto.subtle.importKey(
        'raw', // Formato de la clave (en este caso, la clave es un array de bytes)
        keyEncoded, // Datos de la clave
        { name: 'AES-CBC' }, // Algoritmo de cifrado
        false, // La clave no se puede extraer
        ['encrypt'] // Operación: solo se utilizará para cifrar
    );

    // Generar un vector de inicialización (IV) aleatorio
    const iv = window.crypto.getRandomValues(new Uint8Array(16)); // IV de 16 bytes

    // Cifrar los datos
    const encrypted = await window.crypto.subtle.encrypt(
        {
            name: 'AES-CBC',
            iv: iv
        },
        importKey,
        dataToEncrypt
    );

    // Concatenar el IV cifrado y los datos cifrados
    const encryptedBytes = new Uint8Array(encrypted);
    const encryptedData = new Uint8Array(iv.length + encryptedBytes.length);
    encryptedData.set(iv, 0);
    encryptedData.set(encryptedBytes, iv.length);
    const base64Data = btoa(String.fromCharCode.apply(null, encryptedData));
    return base64Data;
}

export const rsaOAEP256Encrypt = async (randomKey, publicKey) => {
    const pemData = publicKey.replace(/-----BEGIN PUBLIC KEY-----/, '').replace(/-----END PUBLIC KEY-----/, '').replace(/\r?\n|\r/g, '');
    const publicKeyBytes = Uint8Array.from(atob(pemData), c => c.charCodeAt(0));
    const importKey = await window.crypto.subtle.importKey(
        'spki',
        publicKeyBytes,
        { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
        true,
        ['encrypt']
    );
    const toEncrypt = new TextEncoder().encode(randomKey);
    // Cifrar los datos utilizando la clave pública importada
    const encrypted = await window.crypto.subtle.encrypt(
        {
            name: 'RSA-OAEP',
            hash: { name: 'SHA-256' } // Debes especificar el mismo algoritmo de hash que al importar la clave
        },
        importKey,
        toEncrypt
    );
    const encryptedBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(encrypted)));
    return encryptedBase64;
}
