import { Row, Col, Form, Image, Select, Space, Button, Spin } from "antd"
import { useState } from "react";
import { connect } from "react-redux";
import { msalInstance } from "../../auth/Config";

const BankForm = (
    {
        licence,
        bankProductId,
        bankNameList,
        bankProductList,
        onBankSelection,
        onBankProductSelection,
        onShowCredentialsForm,
        setSelectedProduct,
        onHandleGetCredential,
        getCredentialLoader
    }) => {

    const [formBank] = Form.useForm();
    const [selectedBank, setSelectedBank] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [nextDisabled, setNextDisabled] = useState(false);

    const handleChangeBank = (value) => {
        setSelectedBank(value);
        const foundBank = bankNameList.find(bank => bank.name === value);
        if (foundBank){
            setSelectedImage(`https://s.paybook.com${foundBank.image}`);
            onBankSelection(foundBank.products);
            formBank.setFieldsValue({bankProduct:[]});
            onBankProductSelection([]);

            if (nextDisabled){
                setNextDisabled(!nextDisabled)
            }   
        }
    }

    const handleChangeProduct = (value) => {
        setSelectedProduct(value)
        const foundBank = bankProductList.find(bank => bank.id === value);
        if (foundBank){
            onBankProductSelection(foundBank.credentials)
            if (!nextDisabled){
                setNextDisabled(!nextDisabled)
            }        
        }
    }

    const askCredentials = (values) => {
        onShowCredentialsForm(true);
        setNextDisabled(false);
    }

    const goBack = () => {
        setSelectedImage(null);
        setNextDisabled(false);
        onBankSelection([]);
        formBank.resetFields();    
    }

    const useGetLicenseId = () => {
        const account = msalInstance.getAllAccounts()[0];
        var claims = account.idTokenClaims;
        return `${claims.extension_LicenseID}`;
    }
    licence = useGetLicenseId();

    const handleGetCredentials = () => {
        const productId = formBank.getFieldValue("bankProduct");

        onHandleGetCredential(licence, productId)
    }

    return (
        <Spin spinning={getCredentialLoader}>
            <Form form={formBank} layout="vertical" onFinish={askCredentials} >
                <Form.Item label="Selecciona un banco" required={true} value={selectedBank} name="bankOption">
                    <Row justify="space-between">
                        <Col flex="auto">
                            <Select placeholder="Selecciona un banco" onChange={handleChangeBank} >
                            {
                                bankNameList.map(option => (
                                    <Select.Option key={option.name} value={option.name}>{option.name}</Select.Option>
                                ))
                            }
                            </Select>
                        </Col>
                        {selectedImage && (
                            <Col style={{ marginLeft: '8px' }}> {/* Agregar margen izquierdo */}
                                <Image src={selectedImage} style={{ width: '10rem' }} preview={false} name="bankImage"/>
                            </Col>
                        )}
                    </Row>
                </Form.Item>
                <Form.Item label="Selecciona un producto" required={true} value={bankProductId} name="bankProduct">
                    <Select placeholder="Selecciona un producto" onChange={handleChangeProduct}>
                    {
                        bankProductList.map(option => (
                            <Select.Option key={option.id} value={option.id}>{option.product}</Select.Option>
                        ))
                    }
                    </Select>
                </Form.Item>
                <Row justify={"space-around"}>
                    <Form.Item>
                        <Space>
                            <Button type="primary" disabled={!nextDisabled} onClick={() => handleGetCredentials()} style={{ background: "#046ccc" }} >Consultar</Button>
                            <Button type="primary" disabled={!nextDisabled} htmlType="submit">Sincronizar</Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form>
        </Spin>
    )
}

const mapStateToProps = (state) => ({
    bankNameList: state.OpenBankingReducer.bankNameList,
    bankProductList: state.OpenBankingReducer.bankProductList,
    licence: state.OpenBankingReducer.licence,
    bankProductId: state.OpenBankingReducer.bankProductId,
    getCredentialLoader: state.OpenBankingReducer.getCredentialLoader,
});

const mapDispatchToProps = (dispatch) => ({
    onBankSelection: (bankProductList) => {
        dispatch({type: 'SET_BANK_PRODUCT_LIST_REQUEST', bankProductList});
    },
    onBankProductSelection: (credentials) => {
        dispatch({type: 'SET_DYNAMIC_CREDENTIALS_REQUEST', credentials});
    },
    onShowCredentialsForm: (show) => {
        dispatch({type: 'SHOW_CREDENTIALS_FORM', showCredentialsForm: show});
    },
    setSelectedProduct: (value) => {
        dispatch({type: 'SET_SELECTED_PRODUCT_ID', bankProductId: value});
    },
    onHandleGetCredential: (licence, bankProductId) => {
        dispatch({type: 'GET_CREDENTIALS_REQUEST', licence: licence, bankProductId: bankProductId});
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(BankForm);