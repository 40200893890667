import './index.css';
import React from 'react';
import store from "./store/store";
import App from "./containers/App";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import esES from "antd/es/locale/es_ES";
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { msalInstance } from "./auth/Config"
import { MsalProvider } from "@azure/msal-react";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <ConfigProvider locale={esES}>
          <App />
        </ConfigProvider>
      </Provider>
    </MsalProvider>
);

reportWebVitals();