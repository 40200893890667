import { Row, Col, Form, Input, Space, Button, Typography, Spin } from "antd"
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import React, { useState } from "react";
import { connect } from "react-redux";
import TwofaForm from "./TwofaForm";
import TwofaOptionsForm from "./TwofaOptionsForm";
import { msalInstance } from "../../auth/Config";

const CredentialForm = (
    {
        bankProductId,
        twofaChallenge,
        twofaOptionsChallenge,
        credentials,
        licence,
        credentialId,
        setCredentialLoader,
        onHandleCredential,
        onShowBankForm,
        onBankSelection,
        hideCredentialForm
    }) => {

    const [formCredentials] = Form.useForm();
    
    const [formInitialValue, setFormInitialValue] = useState({
        productId: bankProductId
    })
    
    const useGetLicenseId = () => {
        const account = msalInstance.getAllAccounts()[0];
        var claims = account.idTokenClaims;
        return `${claims.extension_LicenseID}`;
    }
    licence = useGetLicenseId();

    const handleCredentials = (values) => {
        values.licence = licence;
        let listValues = []
        let labelValues = [];
        Object.entries(values).forEach(([name, value]) => {
            let labelText = document.querySelector("label[for='"+name+"']");
            if (labelText !== null && labelText !== undefined)
                labelValues[[name]] = labelText.textContent

            listValues = [...listValues, {[name]:value}]   
        });
        onHandleCredential(listValues, labelValues)
    }

    const goBack = () => {
        onBankSelection([]);
        onShowBankForm(true)
    }

    return (
        <>
            {!hideCredentialForm && (
                <>
                    <Spin spinning={setCredentialLoader}>
                        <Form form={formCredentials} layout="vertical" onFinish={handleCredentials} initialValues={formInitialValue}>
                            <Form.Item name="productId" required={true} style={{'display': 'none'}}>
                                <Input value={bankProductId} disabled/>
                            </Form.Item>
                            <Form.Item name="licence" required={false} rules={[{ required: false }]} style={{'display': 'none'}}>
                                <Input disabled defaultValue={licence}/>
                            </Form.Item>
                            <Row justify='start'>
                                <Col>
                                    <Typography.Title level={5}>Datos bancarios</Typography.Title>
                                </Col>
                            </Row>
                            {
                                credentials && (
                                    <>
                                        {
                                            credentials.map((option, index) => (
                                                <Form.Item key={option.name} name={option.name} label={option.label} rules={[{ required: option.required }]} required={option.required}>
                                                    <Input.Password
                                                        maxLength={40}
                                                        iconRender={(visible) => (visible ? 
                                                            <EyeTwoTone style={{ fontSize: '1.3em'}}/> : 
                                                            <EyeInvisibleOutlined style={{ fontSize: '1.3em'}}/>)}
                                                    />
                                                </Form.Item>
                                            ))
                                        }
                                    </>
                                )
                            } 
                            <Row justify={"space-around"}>
                                <Form.Item>
                                    <Space>
                                        <Button type="default" onClick={goBack}>Regresar</Button>
                                        <Button type="primary" htmlType="submit">Consultar</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Form>
                    </Spin>
                </>
            )}

            {twofaChallenge && (
                <>
                    <TwofaForm bankProductId={bankProductId} twofaChallenge={twofaChallenge}/>
                </>
            )}

            {twofaOptionsChallenge && (
                <>
                    <TwofaOptionsForm bankProductId={bankProductId} twofaOptionsChallenge={twofaOptionsChallenge}/>
                </>
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onHandleCredential: (form, labels) => {
        dispatch({type: 'SET_CREDENTIALS_REQUEST', form, labels: labels});
    },
    onShowBankForm: (show) => {
        dispatch({type: 'SHOW_BANK_FORM', showBankForm: show});
    },
    onBankSelection: (bankProductList) => {
        dispatch({type: 'SET_BANK_PRODUCT_LIST_REQUEST', bankProductList});
    },
});

const mapStateToProps = (state) => ({
    bankProductId: state.OpenBankingReducer.bankProductId,
    twofaChallenge: state.OpenBankingReducer.twofaChallenge,
    twofaOptionsChallenge: state.OpenBankingReducer.twofaOptionsChallenge,
    credentials: state.OpenBankingReducer.credentials,
    licence: state.OpenBankingReducer.licence,
    setCredentialLoader: state.OpenBankingReducer.setCredentialLoader,
    credentialId: state.OpenBankingReducer.credentialId,
    hideCredentialForm: state.OpenBankingReducer.hideCredentialForm
});

export default connect(mapStateToProps, mapDispatchToProps)(CredentialForm);