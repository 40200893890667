import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import myReducer from "./reducers/index";
import mySaga from "./sagas/index";

//Dev tools middleware
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

//Create the Saga middleware
const sagaMiddleware = createSagaMiddleware();

//Mount it on the Store
export default createStore(myReducer, applyMiddleware(sagaMiddleware), reduxDevTools);

//Then run the Saga
sagaMiddleware.run(mySaga);